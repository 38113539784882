import { environment } from '../../environments/environment';

const prefixes = {
	user: 'builder/',
	auth: 'auth/',
	property: 'property/'
};

export const User = {
	LOGIN: environment.API + 'login',
	LOGOUT: environment.API + prefixes.auth + 'logout',
	SIGNUP: environment.API + prefixes.user + 'registration',
	ME: environment.API + 'init',
	
};

export const Property = {
	SAVE : environment.API + 'property', 
	FETCH : environment.API + 'property',
}

