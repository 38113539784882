import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service';
import * as API from '../_constants/api.constants';
import { LocalVars } from '../_constants/others.constants';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class CommonService extends BaseService {

	
	
	isLoggedIn() {
		return this.cookieStorage.checkItem(LocalVars.TOKEN_VAR);
	}

	getUser() {
		return this.callApi(API.User.ME);
	}

	doLogOut() {
		return this.callApi(API.User.LOGOUT, 'POST');
	}

	me() {
		return this.callApi(API.User.ME, 'GET');
	}

	// getAllStates(param = {}) {
	// 	return this.callApi(API.Statics.STATE, 'GET', {}, false, param);
	// }
	// getAllCountry(param = {}) {
	// 	return this.callApi(API.Statics.COUNTRY, 'GET', {}, false, param);
	// }
	// getAllCity(param = {}) {
	// 	return this.callApi(API.Statics.CITY, 'GET', {}, false, param);
	// }

	// getPatientDashboard() {
	// 	return this.callApi(API.Dashboard.FETCH_REPORT_PATIENT, 'GET', {}, true);
	// }

	// toggleStatus(status) {
	// 	return this.callApi(API.Doctor.TOGGLE_STATUS +'/'+ status, 'PUT', {}, true);
	// }

	// resendVerification(){
	// 	return this.callApi(API.User.RESEND_EMAIL_VERIFICATION, 'POST');
	// }

	
}
