import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router, NavigationStart } from '@angular/router';
import { CookieStorageService } from './cookie-storage.service';
import { ToastrService } from 'ngx-toastr';

import { LocalVars } from '../_constants/others.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
		public http: HttpClient,
		public cookieStorage: CookieStorageService,
		public router: Router,
		public toaster: ToastrService,
		public sanitizer: DomSanitizer
	) { }

	public callApi(
		functionUrl: string,
		requestType: string = 'GET',
		requestParams: any = {},
		authRequired: boolean = true,
		urlParams: any = {},
		customAuth: boolean = false,
		customAuthParams: any = {}
	): Promise<any> {
		if (!functionUrl) {
			throw new TypeError('Sorry, function url is not defined.');
		}

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');

		if (authRequired) {
			let Token = atob(this.cookieStorage.getItem(LocalVars.TOKEN_VAR));
			headers = headers.append('Authorization', 'Bearer ' + Token);
		}

		if (customAuth) {
			headers = headers.append('Authorization', customAuthParams);
		}

		return this.callApiwithAuth(requestType, functionUrl, requestParams, urlParams, headers)
			.toPromise().then(res => {
				return Promise.resolve(res);
			}).catch(err => {
				console.log('err: ', err);
				if (err.status == 401) {
					this.toaster.error('Your token has expired. Please login again.', 'Token Expired');
					this.cookieStorage.clear();
					window.location.href = environment.BASE_URL + 'login';
					// return;
				}
				return Promise.reject(err);
			});

	}

	public callLoginApi(
		functionUrl: string,
		requestType: string = 'POST',
		requestParams: any = {},
	): Promise<any> {
		if (!functionUrl) {
			throw new TypeError('Sorry, function url is not defined.');
		}
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');

		return this.callApiwithAuth(requestType, functionUrl, requestParams, {}, headers)
			.toPromise().then(res => {
				//console.log('success', res)
				return Promise.resolve(res);
			}).catch(err => {
				return Promise.reject(err);
			});
	}

	private reroute() {
		let navigated: boolean = false;
		this.router.events.subscribe((_: NavigationStart) => {
			if (_.url && !navigated) {
				let urlName = _.url.split('/')[1];
				if (urlName !== 'page-not-found') {
					navigated = true;
					// this.router.navigate(['']); // This line getting called in quick succession causes an error
					window.location.href = environment.BASE_URL;
				}
			}
		});
	}

	private callApiwithAuth(requestType: string, functionUrl: string, requestParams: any, urlParams: any, headers: HttpHeaders) {
		let params = new HttpParams();
		for (const i in urlParams) {
			params = params.append(i, urlParams[i]);
		}

		switch (requestType) {
			case 'GET':
				return this.http.get(functionUrl, { params: params, headers: headers });
			case 'POST':
				return this.http.post(functionUrl, requestParams, { params: params, headers: headers });
			case 'PUT':
				return this.http.put(functionUrl, requestParams, { params: params, headers: headers });
			case 'DELETE':
				// return this.http.delete(functionUrl, requestParams);
				return this.http.request('DELETE', functionUrl, { headers: headers });
		}
	}

	public callApiFileUpload(
		functionUrl: string,
		requestType: string = 'GET',
		requestParams: any = {},
		authRequired: boolean = true,
		urlParams: any = {},
		customAuth: boolean = false,
		customAuthParams: any = {}
	): Promise<any> {
		if (!functionUrl) {
			throw new TypeError('Sorry, function url is not defined.');
		}

		let headers = new HttpHeaders();
		// headers = headers.append('Content-Type', 'multipart/form-data');
		headers = headers.append('Accept', 'application/json');

		if (authRequired) {
			let Token = atob(this.cookieStorage.getItem(LocalVars.TOKEN_VAR));
			headers = headers.append('Authorization', 'Bearer ' + Token);
		}

		if (customAuth) {
			headers = headers.append('Authorization', customAuthParams);
		}

		return this.callApiwithAuth(requestType, functionUrl, requestParams, urlParams, headers)
			.toPromise().then(res => {
				return Promise.resolve(res);
			}).catch(err => {
				if (err.status == 401) {
					this.toaster.error('Your token has expired. Please login again.', 'Token Expired');
					this.cookieStorage.clear();
					window.location.href = environment.BASE_URL + 'login';
					// return;
				}
				return Promise.reject(err);
			});

	}

	
}
