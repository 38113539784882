import { Component, OnInit, Output,EventEmitter,HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
// import { Regex, LocalVars } from '../../_constants/others.constants';
import { environment } from '../../../environments/environment';
// import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../../services/loader.service';
import { CookieStorageService } from 'src/app/services/cookie-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output('closeLoginModal') closeLoginModal: EventEmitter<any> = new EventEmitter;
  loginForm: FormGroup;
  loading = false;
  submitted = false;

  // @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
	// 	let result = confirm("Changes you made may not be saved.");
	// 	event.returnValue = false; // stay on same page
	// }
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private cookieStorage: CookieStorageService,
    // private toaster: ToastrService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get f() { return this.loginForm.controls; }
//   onSubmit() {
//     this.submitted = true;

//     // reset alerts on submit
    

//     // stop here if form is invalid
//     if (this.loginForm.invalid) {
//         return;
//     }

//     this.loading = true;
//     let params = {
//       email : this.f.username.value,
//       password : this.f.password.value
//     }
//     this.loader.show();
//     this.userService.login(params)
//     .then(r => {
//       if ( r['data'] && r['data']['token'] ) {
//         this.cookieStorage.setItem(LocalVars.TOKEN_VAR, btoa(r['data']['token']));
//         this.closeLoginModal.emit(r['data']);
//         this.toaster.success(r.message, 'Login Success');
//         this.getUserDetails();
//         this.loader.hide();
//         // this.ngxService.stop();
//       } else {
//         //this.loader.hide();
//         // this.ngxService.stop();
//       //	this.toaster.success('Token not found!', 'Login Error');
//       }
//     }).catch(err => {
//       const message = err && err.error && err.error.message || "Something went wrong!";
//       // this.toaster.error(message, 'Login Error')
//       // this.loader.hide();
//       // this.ngxService.stop();
//     })
// }

// getUserDetails() {
//   this.userService.me()
//     .then(r => {
//       console.log(r['data'], 'datattt')
//       if ( r['data'] && r['data']['user'] ) {
//         this.completeLogin(r['data']['user']);
//       } else {
//         this.toaster.error('User details not found!', 'User fetch')
//       }
//     }).catch(err => {
//       console.log(err)
//       const message = err && err.error && err.error.message || "Something went wrong!";
//       this.toaster.error(message, 'Login Error')
//     })
// }

// private completeLogin(user: any) {
//   //this.user = user;
//   this.cookieStorage.setItem(LocalVars.USER_VAR, btoa(user.uid));
//   this.cookieStorage.setItem(LocalVars.USER, btoa(JSON.stringify(user)));
  
//   this.router.navigate(['/property/list'], { replaceUrl: true });
// }

  closeLogin() {
		this.closeLoginModal.emit(null);
	}
}
