export const environment = {

    production: false,

    /* API: 'http://59.162.178.98/newbuilt/public/api/v1/', */
    API: 'https://newbuild-dev.dreamztesting.com/api/v1/',
    BASE_URL: 'http://localhost:4200/',
    API_BASE_URL: 'https://livdocsapi-dev.glohtesting.com/',
    ASSET_URL: 'http://localhost/',
    SOCKET_URL: 'https://livdocsapi-dev.glohtesting.com/',
    PUSHER_KEY: '934096a51d5f68961e41',
    PUSHER_CLUSTER: 'us2',
};
