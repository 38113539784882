import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoaderComponent } from './_plugins/loader/loader.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { CookieStorageService } from './services/cookie-storage.service';
import { BaseService } from './services/base.service';

import { ToastrModule } from 'ngx-toastr';
import { LoaderService } from './services/loader.service';
import { CommonService } from './services/common.service';
import { TitleService } from './services/title.service';

import { AuthGuard, LoginGuard, ResetPaswordPageGuard } from './_guards/auth.guard';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ComponentModule } from './_components/component.module';



@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ComponentModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    ToastrModule.forRoot({ closeButton: true, preventDuplicates: true, resetTimeoutOnDuplicate: true }),
    ModalModule.forRoot(),
    
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    ResetPaswordPageGuard,
    CookieStorageService,
    BaseService,
    LoaderService,
    CommonService,
    TitleService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
