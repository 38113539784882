import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { CookieStorageService } from 'src/app/services/cookie-storage.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.css']
})
export class WebHeaderComponent implements OnInit {

  constructor(private cookieStorage: CookieStorageService,
    private toaster: ToastrService,
    private router: Router,
    private loader: LoaderService, private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }


  logout() {
    Swal.fire({
      title: 'Are you sure you want to logout?',
      showCancelButton: true,
      confirmButtonText: 'Yes, Sure',
      cancelButtonText: 'No',
      confirmButtonColor: '#f37135',
      width: '25rem'
    }).then((result) => {
      if (result.value) {
        this.logoutConfirm()
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }
  logoutConfirm() {
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
      this.commonService.doLogOut();
      this.clearAll();
    }, 1000);
  }

  private clearAll() {
    this.cookieStorage.clear();
    this.router.navigate(['']);
  }

}
