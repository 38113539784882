import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CookieStorageService } from '../services/cookie-storage.service';

import { LocalVars } from '../_constants/others.constants';
import { CommonService } from '../services/common.service';


@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
	) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		// console.log(this.cookieStorage.checkItem(LocalVars.TOKEN_VAR), this.cookieStorage.checkItem(LocalVars.USER), 'ttttttttt')
		if (this.cookieStorage.checkItem(LocalVars.TOKEN_VAR) && this.cookieStorage.checkItem(LocalVars.USER) ) {			
			let userID = atob(this.cookieStorage.getItem(LocalVars.USER_VAR));
		} else {
			this.router.navigate(['']);
		}
		
		return true;
	}
}


@Injectable()
export class LoginGuard implements CanActivate {
	constructor(private router: Router, private cookieStorage: CookieStorageService) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {			
			if (this.cookieStorage.checkItem(LocalVars.TOKEN_VAR) && this.cookieStorage.checkItem(LocalVars.USER) ) {
				this.router.navigate(['/dashboard'], { replaceUrl: true });
			}
			
			return true;
	}
}

@Injectable()
export class ResetPaswordPageGuard implements CanActivate {
	constructor(
		private router: Router, 
		private cookieStorage: CookieStorageService,
		private commonService: CommonService,
	) {

	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		if (this.cookieStorage.checkItem(LocalVars.TOKEN_VAR)) {
			this.commonService.doLogOut();
			this.cookieStorage.clear();
		}

		return true;
	}
}



