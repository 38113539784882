export const Regex = {
	email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	//password: `(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}`,
	password: /^(?=.*\d)(?=.*[a-zA-Z]).{6,16}$/,
	// phone: `^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*`,
	phone: /^\d{10}$/,
	coords: `(((-|)[0-9]*\.[0-9]*)|((-|)[0-9]*(\.)[0-9]*))`,
	singleWord: `[A-Za-z0-9:_\-]*`,
	deviceCreds: `[A-Za-z0-9_//\-]*`,
	domainName: `[A-Za-z0-9//\-]*`,
	paramName: `[a-zA-Z0-9_]*`,
	number: `[0-9]*`,
	float: `^(?=.)([+-]?([0-9]*)(\.([0-9]+))?)$`,
	zipcode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
	url: `(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?`,
	color: `^(#[0-9a-f]{3}|#(?:[0-9a-f]{2}){2,4}|(rgb|hsl)a?\((-?\d+%?[,\s]+){2,3}\s*[\d\.]+%?\))$`
};

const _localVarPrefix = 'new_built_builder_';
export const LocalVars = {
	TOKEN_VAR: _localVarPrefix + 'Token',
	TOKEN_TEMP_VAR: _localVarPrefix + 'Token_TEMP',
	USER_VAR: _localVarPrefix + 'User',
	USER: _localVarPrefix + 'User_Details',
	DOMAIN: _localVarPrefix + 'Domain',
	PER_PAGE_ITEM: _localVarPrefix + 'PerPageItem',
};

export const RolePermission = {
	'admin' : [ 'dashboard', 'doctor', 'patient', 'appointment', 'medicine', 'tests' ],
	'sub-admin' : [ 'dashboard', 'doctor', 'patient', 'appointment', 'medicine', 'tests' ]
}

export const CountryID = 231;

export const medicationDirections = [
	{ id: 0, name: 'Daily' },
	{ id: 1, name: 'Twice a day' },
	{ id: 2, name: 'Three times a day' },
	{ id: 3, name: 'Four times a day' },
	{ id: 4, name: 'At Bed time' },
	{ id: 5, name: 'As needed' }
];

export const medicationStatus = [
	{ id: 0, name: 'Current' },
	{ id: 1, name: 'Past' }
];

// export const familyHistoryDropdown = [
// 	{id: 0, name: 'Grandparent'},
// 	{id: 1, name: 'Parent'},
// 	{id: 2, name: 'Siblings'},
// 	{id: 3, name: 'Children'}
// ];

export const familyHistoryDropdown = [
	{id : 0, name : 'Father'}, {id : 1, name : 'Mother'}, {id : 2, name : 'Brother'}, {id : 3, name : 'Sister'}, {id : 4, name : 'Son'} , {id : 5, name : 'Daughter'} , {id : 6, name : 'Husband'}, {id : 7, name : 'Wife'}, {id : 8, name : 'Grandfather'}, {id : 9, name : 'Grandmother'}, {id : 10, name : 'Domestic Partner'}, {id : 11, name : 'Other'}
];

export const healthProblems = [
	{
		check: {id: 0, name: 'Anxiety', formControl: 'healthProblemsAnxietyCheck'},
		details: {formControl: 'healthProblemsAnxietyText', show: false},
	},
	{
		check: {id: 1, name: 'Arrhythmias', formControl: 'healthProblemsArrhythmiasCheck'},
		details: {formControl: 'healthProblemsArrhythmiasText', show: false},
	},
	{
		check: {id: 2, name: 'Asthma', formControl: 'healthProblemsAsthmaCheck'},
		details: {formControl: 'healthProblemsAsthmaText', show: false},
	},
	{
		check: {id: 3, name: 'COPD', formControl: 'healthProblemsCOPDCheck'},
		details: {formControl: 'healthProblemsCOPDText', show: false},
	},
	{
		check: {id: 4, name: 'Cancer', formControl: 'healthProblemsCancerCheck'},
		details: {formControl: 'healthProblemsCancerText', show: false},
	},
	{
		check: {id: 5, name: 'Depression', formControl: 'healthProblemsDepressionCheck'},
		details: {formControl: 'healthProblemsDepressionText', show: false},
	},
	{
		check: {id: 6, name: 'Eczema', formControl: 'healthProblemsEczemaCheck'},
		details: {formControl: 'healthProblemsEczemaText', show: false},
	},
	{
		check: {id: 7, name: 'Headaches/Migraines', formControl: 'healthProblemsHeadachesMigrainesCheck'},
		details: {formControl: 'healthProblemsHeadachesMigrainesText', show: false},
	},
	{
		check: {id: 8, name: 'Heart Problems', formControl: 'healthProblemsHeartProblemsCheck'},
		details: {formControl: 'healthProblemsHeartProblemsText', show: false},
	},
	{
		check: {id: 9, name: 'High Blood Pressure', formControl: 'healthProblemsHighBloodPressureCheck'},
		details: {formControl: 'healthProblemsHighBloodPressureText', show: false},
	},
	{
		check: {id: 10, name: 'Kidney Problems', formControl: 'healthProblemsKidneyProblemsCheck'},
		details: {formControl: 'healthProblemsKidneyProblemsText', show: false},
	},
	{
		check: {id: 11, name: 'Panic Attacks', formControl: 'healthProblemsPanicAttacksCheck'},
		details: {formControl: 'healthProblemsPanicAttacksText', show: false},
	},
	{
		check: {id: 12, name: 'Psoriasis', formControl: 'healthProblemsPsoriasisCheck'},
		details: {formControl: 'healthProblemsPsoriasisText', show: false},
	},
	{
		check: {id: 13, name: 'Seizures', formControl: 'healthProblemsSeizuresCheck'},
		details: {formControl: 'healthProblemsSeizuresText', show: false},
	},
	{
		check: {id: 14, name: 'Stroke', formControl: 'healthProblemsStrokeCheck'},
		details: {formControl: 'healthProblemsStrokeText', show: false},
	},
	{
		check: {id: 15, name: 'Thyroid Diesease', formControl: 'healthProblemsThyroidDieseaseCheck'},
		details: {formControl: 'healthProblemsThyroidDieseaseText', show: false},
	},
	{
		check: {id: 16, name: 'Uninary Tract Infection', formControl: 'healthProblemsUninaryTractInfectionCheck'},
		details: {formControl: 'healthProblemsUninaryTractInfectionText', show: false},
	},
	{
		check: {id: 17, name: 'Other Problems', formControl: 'healthProblemsOtherProblemsCheck'},
		details: {formControl: 'healthProblemsOtherProblemsText', show: false},
	}
];

export const familyHistory = [
	{
		check: {id: 0, name: 'Anxiety', formControl: 'familyHistoryAnxietyCheck'},
		details: {formControl: 'familyHistoryAnxietyText', show: false},
		dropdown: {formControl: 'familyHistoryAnxietySelect', show: false},
	},
	{
		check: {id: 1, name: 'Arrhythmias', formControl: 'familyHistoryArrhythmiasCheck'},
		details: {formControl: 'familyHistoryArrhythmiasText', show: false},
		dropdown: {formControl: 'familyHistoryArrhythmiasSelect', show: false},
	},
	{
		check: {id: 2, name: 'Asthma', formControl: 'familyHistoryAsthmaCheck'},
		details: {formControl: 'familyHistoryAsthmaText', show: false},
		dropdown: {formControl: 'familyHistoryAsthmaSelect', show: false}
	},
	{
		check: {id: 3, name: 'COPD', formControl: 'familyHistoryCOPDCheck'},
		details: {formControl: 'familyHistoryCOPDText', show: false},
		dropdown: {formControl: 'familyHistoryCOPDSelect', show: false},
	},
	{
		check: {id: 4, name: 'Cancer', formControl: 'familyHistoryCancerCheck'},
		details: {formControl: 'familyHistoryCancerText', show: false},
		dropdown: {formControl: 'familyHistoryCancerSelect', show: false},
	},
	{
		check: {id: 5, name: 'Depression', formControl: 'familyHistoryDepressionCheck'},
		details: {formControl: 'familyHistoryDepressionText', show: false},
		dropdown: {formControl: 'familyHistoryDepressionSelect', show: false},
	},
	{
		check: {id: 6, name: 'Eczema', formControl: 'familyHistoryEczemaCheck'},
		details: {formControl: 'familyHistoryEczemaText', show: false},
		dropdown: {formControl: 'familyHistoryEczemaSelect', show: false},
	},
	{
		check: {id: 7, name: 'Headaches/Migraines', formControl: 'familyHistoryHeadachesMigrainesCheck'},
		details: {formControl: 'familyHistoryHeadachesMigrainesText', show: false},
		dropdown: {formControl: 'familyHistoryHeadachesMigrainesSelect', show: false},
	},
	{
		check: {id: 8, name: 'Heart Problems', formControl: 'familyHistoryHeartProblemsCheck'},
		details: {formControl: 'familyHistoryHeartProblemsText', show: false},
		dropdown: {formControl: 'familyHistoryHeartProblemsSelect', show: false},
	},
	{
		check: {id: 9, name: 'High Blood Pressure', formControl: 'familyHistoryHighBloodPressureCheck'},
		details: {formControl: 'familyHistoryHighBloodPressureText', show: false},
		dropdown: {formControl: 'familyHistoryHighBloodPressureSelect', show: false},
	},
	{
		check: {id: 10, name: 'Kidney Problems', formControl: 'familyHistoryKidneyProblemsCheck'},
		details: {formControl: 'familyHistoryKidneyProblemsText', show: false},
		dropdown: {formControl: 'familyHistoryKidneyProblemsSelect', show: false},
	},
	{
		check: {id: 11, name: 'Panic Attacks', formControl: 'familyHistoryPanicAttacksCheck'},
		details: {formControl: 'familyHistoryPanicAttacksText', show: false},
		dropdown: {formControl: 'familyHistoryPanicAttacksSelect', show: false},
	},
	{
		check: {id: 12, name: 'Psoriasis', formControl: 'familyHistoryPsoriasisCheck'},
		details: {formControl: 'familyHistoryPsoriasisText', show: false},
		dropdown: {formControl: 'familyHistoryPsoriasisSelect', show: false},
	},
	{
		check: {id: 13, name: 'Seizures', formControl: 'familyHistorySeizuresCheck'},
		details: {formControl: 'familyHistorySeizuresText', show: false},
		dropdown: {formControl: 'familyHistorySeizuresSelect', show: false},
	},
	{
		check: {id: 14, name: 'Stroke', formControl: 'familyHistoryStrokeCheck'},
		details: {formControl: 'familyHistoryStrokeText', show: false},
		dropdown: {formControl: 'familyHistoryStrokeSelect', show: false},
	},
	{
		check: {id: 15, name: 'Thyroid Diesease', formControl: 'familyHistoryThyroidDieseaseCheck'},
		details: {formControl: 'familyHistoryThyroidDieseaseText', show: false},
		dropdown: {formControl: 'familyHistoryThyroidDieseaseSelect', show: false},
	},
	{
		check: {id: 16, name: 'Uninary Tract Infection', formControl: 'familyHistoryUninaryTractInfectionCheck'},
		details: {formControl: 'familyHistoryUninaryTractInfectionText', show: false},
		dropdown: {formControl: 'familyHistoryUninaryTractInfectionSelect', show: false},
	},
	{
		check: {id: 17, name: 'Other Problems', formControl: 'familyHistoryOtherProblemsCheck'},
		details: {formControl: 'familyHistoryOtherProblemsText', show: false},
		dropdown: {formControl: 'familyHistoryOtherProblemsSelect', show: false},
	}
];

export const seekCares = [ 'Emergency Room', 'Urgent Care for retail clinic', 'Specialist', 'Primary care physician', 'No Treatment'];

export const relationshipDropdown = [ {gender : 1, name : 'Father'}, {gender : 2, name : 'Mother'} , {gender : 1, name : 'Brother'}, {gender : 2, name : 'Sister'}, {gender : 1, name : 'Son'} , {gender : 2, name : 'Daughter'},{gender : 1, name : 'Husband'} , {gender : 2, name : 'Wife'} ,{gender : 1, name : 'Grandfather'}, {gender : 2, name : 'Grandmother'}, {gender : 0, name : 'Domestic Partner'}, {gender : 0, name : 'Other'}];

export const covid_answers = [
	{
		
		'question_key': "covid_question_1",
		'question' : "Does patient has COVID symtoms (fever or feverish cough, sore throught, and/or shortness of breadth)",
		'answer': '',
		'input_type': 2
	},
	{
		'question_key': "covid_question_2",
		'question' : 'Patient has pending COVID-19 test',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_3",
		'question' : 'Patient living with household members with lab-confirmed positive case in last 14 days.',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_4",
		'question' : 'Patient has been in close contact with a person with lab-confirmed COVID 19+ case in last 14 days',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_5",
		'question' : 'Patient Has been in any on of the following occupation then (list of occupation)',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_6",
		'question' : ' In the last 14 days patient has travelled internationally',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_7",
		'question' : 'In last 14 days, visiting or living in area with increased COVID 19 case, and social distancing measures, taken, by local govt, (i.e school closures, businesses with remote work in place, banning large gathering/ event cancellations/parks, beaches closure ) ',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_8",
		'question' : 'The patient has any of the cronic condition ( list of confitions)',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_9",
		'question' : 'Does patient meet criteria for Person Under Investigation (PUI), for COVID-19?',
		'answer': '',
		'input_type': 2
	},
	{
		'question_key': "covid_question_10",
		'question' : 'Patient is 50 years or older',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_11",
		'question' : 'Patient smokes/vapes regularly',
		'answer': '',
		'input_type': 1
	},
	{
		'question_key': "covid_question_12",
		'question' : 'Houshold member have any of the following, and long text below',
		'answer': '',
		'input_type': 1
	}
]


