
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginComponent } from './login/login.component';

@NgModule({
	imports: [
		// BrowserModule,
		CommonModule,
		RouterModule,
        FormsModule,
		ReactiveFormsModule,
		NgbModule
	],
	declarations: [
        NotFoundComponent,
        LoginComponent
	],
	exports: [
		NotFoundComponent,
		LoginComponent
	],
})

export class ComponentModule { }
