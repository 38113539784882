import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LocalVars } from '../_constants/others.constants'

@Injectable()
export class CookieStorageService {

	constructor(
		private cookieService: CookieService
	) { }

	getItem(index: string): any {
		return this.cookieService.get(index);
	}

	getAll(): any {
		return this.cookieService.getAll();
	}

	setItem(index: string, data: any, expires?: number | Date): void {
		if (!expires) {
			expires = 365;
		}

		let path = '/';
		let domain = environment.BASE_URL;
		let secure = true;
		let sameSite = "None";

		// var r = this.cookieService.set( index, data, expires, path, domain, secure, "Lax" );
		var r = this.cookieService.set(index, data);
	}

	removeItem(index: string, path?: string): void {
		if (!path) {
			path = '/';
		}

		let domain = environment.BASE_URL;
		let secure = true;
		let sameSite = "None";

		this.cookieService.delete(index);
	}

	clear(path?: string): void {
		if (!path) {
			path = '/';
		}
		let domain = environment.BASE_URL;
		let secure = true;
		let sameSite = "None";

		this.cookieService.delete(LocalVars.DOMAIN);
		this.cookieService.delete(LocalVars.TOKEN_VAR);
		this.cookieService.delete(LocalVars.USER);
		this.cookieService.delete(LocalVars.USER_VAR);
	}

	checkItem(index: string) {
		if (this.cookieService.check(index)) {
			return true;
		} else {
			return false;
		}
	}

}