import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';

import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminFooterComponent } from './admin-footer/admin-footer.component';
import { AdminSedebarComponent } from './admin-sedebar/admin-sedebar.component';
import { WebLayoutComponent } from './web-layout/web-layout.component';
import { WebHeaderComponent } from './web-header/web-header.component';
import { WebFooterComponent } from './web-footer/web-footer.component';
import { ComponentModule } from '../_components/component.module';


@NgModule({
  declarations: [
    WebHeaderComponent, 
    WebFooterComponent, 
    AdminHeaderComponent, 
    AdminFooterComponent, 
    AdminSedebarComponent,
    WebLayoutComponent
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    ComponentModule
  ],
  exports: [
    WebHeaderComponent,
    WebFooterComponent,
    WebLayoutComponent
  ]
})
export class LayoutModule { }
