import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebLayoutComponent } from './layout/web-layout/web-layout.component';
import { LoginGuard, AuthGuard } from './_guards/auth.guard';
import { NotFoundComponent } from './_components/not-found/not-found.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: WebLayoutComponent,
  //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  //   canActivate:[LoginGuard]
  // },
  {
    path: '',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate:[LoginGuard]
  },
  {
    path: 'property',
    component: WebLayoutComponent,
    loadChildren: () => import('./property/property.module').then(m => m.PropertyModule),
    canActivate:[]
  },
  {
    path: 'dashboard',
    component: WebLayoutComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate:[]
  },
  // Others
	{ path: 'page-not-found', component: NotFoundComponent },
	{ path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
