import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import * as API from '../_constants/api.constants';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
	login(params: any) {
		return this.callLoginApi(API.User.LOGIN, 'POST', params);
	}

	me() {
		return this.callApi(API.User.ME, 'GET');
	  }
	  
	  registration(params: any) {
		return this.callApi(API.User.SIGNUP, 'POST', params, false);
	}
}